import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';

import SEO from '../components/Seo/Seo';

const Container = styled.section`
  height: 100%;
  padding-top: 12rem;
`;

const NotFoundPage = () => {
  const { t } = useTranslation('translations');

  return (
    <Container>
      <SEO title={`404: ${t('pages.404.pageTitle')}`} />
      <h1>{t('pages.404.heading')}</h1>
      <p>{t('pages.404.paragraph')}</p>
    </Container>
  );
};
export default NotFoundPage;
